// extracted by mini-css-extract-plugin
export var alignLeft = "G_rd d_fp d_bG d_dv";
export var alignCenter = "G_bP d_fq d_bD d_dw";
export var alignRight = "G_rf d_fr d_bH d_dx";
export var productsContainer = "G_wh d_dW";
export var productsTextWrapper = "G_wj d_0 d_9 d_w d_cG";
export var productsImageElement = "G_lF";
export var productsModalWrapper = "G_wk d_bz d_bL";
export var productsModalWrapperDesign3 = "G_l1 d_l1 d_bz d_bM d_bT d_bD d_cG d_bf";
export var modalLeftColumn = "G_wl d_r d_H";
export var modalLeftColumnDesign2 = "G_wm d_r d_H";
export var modalCloseIcon = "G_wn d_dB";
export var modalRightColumn = "G_wp d_r d_H d_bT d_bz d_bM d_cG";
export var productsComponentsWrapperLeft = "G_lY d_lY d_y d_w d_cv d_dB";
export var modalCloseButton = "G_wq d_w d_bz d_bL d_bP d_bH";
export var modalCloseButtonDesign3 = "G_wr d_w d_bz d_bL d_bP d_bH";
export var modalTextWrapperDesign3 = "G_ws d_H d_bz d_bM d_bP";
export var modalTextWrapper = "G_wt d_bz d_bM d_bP";
export var modalCarouselWrapper = "G_wv d_H d_bz d_bL d_bD";
export var carouselWrapper = "G_k6 d_H d_bz d_bL d_bD";
export var productsCarouselImageWrapper = "G_ww d_lS d_H d_w";
export var productsCarouselImageWrapperSides = "G_wx d_H";
export var productsCarouselImageWrapperDesign3 = "G_lT d_lT d_bf";
export var productsCarouselWrapper = "G_lV d_lV d_bz d_bD d_bP d_w d_H";
export var productsCarouselWrapperDesign3 = "G_lW d_lW d_bz d_bD d_bP d_w";
export var productsCarouselImg = "G_mX d_lX d_w d_H d_bR";
export var productsImageModalWrapper = "G_lH";
export var productsImageElementDesign3 = "G_lJ d_lJ d_hH d_w d_H d_bR";
export var productsContainerFull = "G_wy d_dT";
export var productsMainHeader = "G_l3 d_l3 d_l2 d_w d_ct";
export var productsMainSubtitle = "G_l4 d_l4 d_l2 d_w d_cy";
export var productsComponentHeading = "G_l5 d_l5";
export var productsComponentParagraph = "G_l6 d_l6";
export var productsCard = "G_lK d_lK d_bd d_0 d_8 d_7 d_4 d_9 d_by d_y d_W d_H";
export var productsCardWrapper = "G_lL d_lL d_by d_w d_Z";
export var productsCardWrapperDesign2 = "G_lM d_lM d_w d_H d_bz d_bM d_bT";
export var productsTextBlockDesign2Wrapper = "G_wz d_cG";
export var productsTextBlockDesign3Wrapper = "G_lN d_lN d_w d_bz d_bM d_bD d_bP d_cG d_fn d_0";
export var productsTextBlockDesign2 = "G_lP d_lP d_bz d_bM d_H";
export var productsTextBlockDesign3 = "G_lQ d_lQ d_bz d_bM d_bP d_bl";
export var productsComponentsWrapperDesign2 = "G_lZ d_lZ d_y d_w d_bz d_bM d_cv d_bT";
export var productsComponentsWrapperDesign3 = "G_l0 d_l0 d_y d_w d_bz d_bM d_cv d_dB";
export var colEqualHeights = "G_wB d_bz";
export var productsImageWrapperLeft = "G_lB d_lB d_w d_Z d_bx";
export var productsImageWrapperDesign2 = "G_lC d_lC d_w";
export var design2TextWrapper = "G_wC d_r d_bC d_bP d_bJ";
export var exceptionWeight = "G_v7 C_t1";