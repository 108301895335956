// extracted by mini-css-extract-plugin
export var alignLeft = "z_rd d_bG";
export var alignCenter = "z_bP d_bD";
export var alignRight = "z_rf d_bH";
export var textAlignLeft = "z_tl";
export var textAlignCenter = "z_tm";
export var textAlignRight = "z_tn";
export var embedInnerWrapperDesign1 = "z_tp d_bM";
export var embed = "z_tq d_b1";
export var titleMargin = "z_tc d_cw d_dw";
export var subtitleMargin = "z_tr d_cs d_dw";
export var paragraphMargin = "z_ts d_cw d_dw";
export var SubtitleSmall = "z_pd C_pd C_tz C_tM";
export var SubtitleNormal = "z_pf C_pf C_tz C_tN";
export var SubtitleLarge = "z_pg C_pg C_tz C_tP";
export var BodySmall = "z_tt C_tt C_tz C_tT";
export var BodyNormal = "z_tv C_tv C_tz C_tV";
export var BodyLarge = "z_tw C_tw C_tz C_tW";