// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "y_sP d_bD";
export var datasheetRowWrapper = "y_lt d_lt d_bK";
export var datasheetLeftWrapper = "y_sQ d_bz d_bP";
export var datasheetWrapperFull = "y_sR d_cD";
export var datasheetWrapperFullLeft = "y_qT d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "y_mv d_lv";
export var contentWrapperCenter = "y_sS d_lz";
export var datasheetLeftWrapperCenter = "y_sT d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "y_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "y_sV d_lw";
export var datasheetFooter = "y_ly d_ly";
export var alignLeft = "y_rd d_dv";
export var alignCenter = "y_bP d_dw";
export var alignRight = "y_rf d_dx";
export var datasheetInnerWrapperNewLeft = "y_sW d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "y_sX d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "y_sY d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "y_sZ d_bK";
export var datasheetSubtitle = "y_s0 d_cx";
export var tableRow = "y_s1";
export var cell = "y_s2";
export var tableRowWrapper = "y_s3 d_w d_dv";
export var tableHeadingCell = "y_s4";
export var tableHeading = "y_s5";
export var tableRowStriped = "y_s6";
export var tableRowHeading = "y_s7";
export var dataTable = "y_s8 d_w";
export var imageWrapper = "y_rj d_fg d_Z";
export var imageWrapperFull = "y_s9 d_H d_w d_bf d_Z";
export var imageWrapperIcon = "y_tb";
export var titleMargin = "y_tc d_cs";
export var datasheetInnerInnerWrapper = "y_td d_w";
export var hasLabels = "y_tf";
export var label = "y_tg";
export var SmallSmall = "y_th C_th C_tz C_tX";
export var SmallNormal = "y_tj C_tj C_tz C_tY";
export var SmallLarge = "y_tk C_tk C_tz C_tV";