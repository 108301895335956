// extracted by mini-css-extract-plugin
export var alignDiscLeft = "D_vJ d_fp d_bG d_dv";
export var alignLeft = "D_rd d_fp d_bG d_dv";
export var alignDiscCenter = "D_vK d_fq d_bD d_dw";
export var alignCenter = "D_bP d_fq d_bD d_dw";
export var alignDiscRight = "D_vL d_fr d_bH d_dx";
export var alignRight = "D_rf d_fr d_bH d_dx";
export var footerContainer = "D_vM d_dW d_bW";
export var footerContainerFull = "D_vN d_dT d_bW";
export var footerHeader = "D_kf d_kf";
export var footerTextWrapper = "D_vP d_w";
export var footerDisclaimerWrapper = "D_km d_km d_ck";
export var badgeWrapper = "D_vQ d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "D_vR d_bz d_bJ d_bN d_bL";
export var wide = "D_vS d_cy";
export var right = "D_vT d_bK";
export var line = "D_fk d_fl d_cv";
export var badgeDisclaimer = "D_vV d_bC d_bP d_bJ";
export var badgeContainer = "D_vW d_bz d_bH d_bP";
export var badge = "D_vX";
export var padding = "D_vY d_c7";
export var end = "D_vZ d_bH";
export var linkWrapper = "D_v0 d_dB";
export var link = "D_mC d_dB";
export var colWrapper = "D_v1 d_cx";
export var consent = "D_f d_f d_bC d_bP";
export var disclaimer = "D_v2 d_bz d_bJ";
export var media = "D_v3 d_bx d_dy";
export var itemRight = "D_v4";
export var itemLeft = "D_v5";
export var itemCenter = "D_v6";
export var exceptionWeight = "D_v7 C_t1";