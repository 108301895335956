// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "H_wD d_gS d_cw d_dv";
export var quoteParagraphCenter = "H_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "H_gV d_gV d_cw d_dx";
export var quoteRowLeft = "H_wF d_bG";
export var quoteRowCenter = "H_wG d_bD";
export var quoteRowRight = "H_wH d_bH";
export var quoteWrapper = "H_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "H_gR d_gR";
export var quoteExceptionSmall = "H_vp C_vp";
export var quoteExceptionNormal = "H_vq C_vq";
export var quoteExceptionLarge = "H_vr C_vr";
export var quoteAuthorExceptionSmall = "H_vs C_vs";
export var quoteAuthorExceptionNormal = "H_vt C_vt";
export var quoteAuthorExceptionLarge = "H_vv C_vv";