// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "F_v8 d_gv d_cs";
export var heroHeaderCenter = "F_gw d_gw d_cs d_dw";
export var heroHeaderRight = "F_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "F_v9 d_gr d_cw";
export var heroParagraphCenter = "F_gs d_gs d_cw d_dw";
export var heroParagraphRight = "F_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "F_wb d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "F_wc d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "F_wd d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "F_wf d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "F_wg d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "F_vh C_vh";
export var heroExceptionNormal = "F_vj C_vj";
export var heroExceptionLarge = "F_vk C_vk";
export var Title1Small = "F_t4 C_t4 C_tz C_tB";
export var Title1Normal = "F_t5 C_t5 C_tz C_tC";
export var Title1Large = "F_t6 C_t6 C_tz C_tD";
export var BodySmall = "F_tt C_tt C_tz C_tT";
export var BodyNormal = "F_tv C_tv C_tz C_tV";
export var BodyLarge = "F_tw C_tw C_tz C_tW";